import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import * as React from 'react';

import { useNavigate } from "react-router-dom";
import { useContext, useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import noteContext from './NoteContext';



function BasicExample() {

  const navigate = useNavigate();
  const a = useContext(noteContext);

  let navItems = ['Home', 'Transaction', 'Logout'];

  const transactionItems = a.role === "10" ? [
    'homeworks', 'Teacher_report', 'TeacherAsign', 'TeacherAsignReport', 'Student_report', 'Student_print_report', 'teachers'
  ] : [
    'homeworks', 'report'
  ];

  function sendto(provider) {
    if (provider === "Home")
      navigate("/");
    else if (provider === "Logout")
      navigate("/logout");
    else
      navigate(provider);
  }


  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">School HW App</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">


 
         

            <NavDropdown title="Dropdown" id="basic-nav-dropdown">



              {transactionItems.map((item) => (


                <NavDropdown.Item key={item} onClick={() => sendto(item)} sx={{ color: '#000' }}>
                  {item}
                </NavDropdown.Item>


              ))}




            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;