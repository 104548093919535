import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import noteContext from './NoteContext';
import API from "./API";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Form from 'react-bootstrap/Form';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const EditStudent = () => {
    const [fields, setFields] = useState([]);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const { token } = useContext(noteContext);
    const { sr } = useParams();

    const captureData = (index, label, value) => {
        setFields(prevFields => {
            const updatedFields = [...prevFields];
            updatedFields[index] = { ...updatedFields[index], label, value };
            return updatedFields;
        });
    };

    const loadForm = async (sr) => {
        try {
            const res = await API.get(`StudentFormFields/${sr}`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            setFields(res.data);
        } catch (error) {
            console.error("Error loading form:", error);
        }
    };

    const handleSubmit = async () => {
        try {
            const studentData = { sr, json: fields };
            const res = await API.post("updateStudent", studentData, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            setMessage(res.data.message);
            setOpen(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {
        loadForm(sr);
    }, [sr]);

    return (
        <>
            {fields.map((item, index) => (
                <Form.Group key={index} className="mb-3">
                    <Form.Label>{item.name}</Form.Label>
                    <Form.Control
                        onChange={(e) => captureData(index, item.label, e.target.value)}
                        type={item.type}
                        value={item.value}
                    />
                    
                </Form.Group>
            ))}
            <Button onClick={handleSubmit} variant="contained">
                <DoneAllIcon />
            </Button>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                message={message}
            />
        </>
    );
};